body {
	margin: 0;
	background: #181818 !important;
	color: #f4f4f4 !important;
	font-family: 'Montserrat';
	overscroll-behavior: none;
}

html{
	overscroll-behavior: none;
}

.Toastify__toast-container {
	display: flex;
	width: 100%;
	justify-content: center;
}

.Toastify__toast-theme--light {
	background-image: linear-gradient(68.02deg, #775da8 0%, #f06b5d 100%);
	border-radius: 20px;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
	width: 340px;
	color: #f4f4f4;
	height: 70px;
	font-family: Montserrat;
	font-size: 14px;
	line-height: 17px;
	text-align: center;
}

.MuiToolbar-root {
	min-height: 74px;
	padding: 12px 25px;
}

.playerButtons {
	stroke: inherit;
}

.animation {
	visibility: hidden;
	opacity: 0;
	height: 0;
	transition: all 0.5s cubic-bezier(0.18, 0.89, 0.32, 1.28);
}
.show {
	visibility: visible;
	height: auto;
	opacity: 1;
}

.MuiSlider-thumb {
	display: none;
}

.MuiSkeleton-root {
	background-color: #313131;
}
.MuiSkeleton-root::after {
	background: linear-gradient(90deg, transparent, #262626, transparent);
}

.MuiTabs-indicator {
	display: none;
}

.MuiBackdrop-root {
	background-color: #282828 !important;
	opacity: 0.5 !important;
}

#song-title-bottom-player {
	padding-left: 0px;
	padding-right: 15px;
	overflow: hidden;
	width: auto;
	max-width: 300px;
}

#song-title-bottom-player-mobile {
	padding-left: 0px;
	padding-right: 15px;
	overflow: hidden;
	width: auto;
	max-width: 300px;
}

/* .MuiTypography-root span {
	display: inline-block;
} */

.MuiTypography-root.animateText {
	position: relative;
	animation: leftright 7s infinite alternate ease-in-out;
}

@keyframes leftright {
	0%,
	10% {
		transform: translateX(0%);
		left: 0%;
	}
	80%,
	100% {
		transform: translateX(-100%);
		left: 100%;
	}
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type='number'] {
	-moz-appearance: textfield;
}

@media (max-width: 560px) {
	::-webkit-scrollbar {
		background: transparent;
		height: 0px;
	}

}

@media (display-mode: standalone)
{
	html {
		overscroll-behavior: contain;
	}
	body {
		overscroll-behavior: contain;
	}
}